import { Link } from "react-router-dom"
import { Col, Row, Alert, Card } from "antd"
import { useEffect, useState } from "react"
import { PMSRegisterLayout, PMSAuthStyle } from "../../../../component/layout/PMSAuthLayout"
import AuthApi from '../../../../app/utils/auth'
import useQuery from "../../main/hooks/useQuery"
import { useParams, useHistory } from "react-router-dom"
import DebounceSelectOrganization from "../../../../component/debounce_select_organization"
import Customer from "../../../../app/service/customer"

const PMSRegister = () => {

    const params = useParams('id')

    const query = useQuery()
    const history = useHistory()
    const source = query.get('source')
    const customer = new Customer()

    const auth = new AuthApi()
    const [apiCallStatus, setApiCallStatus] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')
    const [provinceList, setProvinceList] = useState([ <
        option key = { '0' }
        value = "" > Pilih... < /option>
    ])
    const [viewportWidth, setViewportWidth] = useState()
    const [customerInfoField, setCustomerInfoField] = useState({ source: "", company: { value: "", label: "" } })
    const [cityList, setCityList] = useState([])
    const [optionOnNotFound, setOptionOnNotFound] = useState([])
    const [formField, setFormField] = useState({
        name: "",
        gender: "",
        email: "",
        password: "",
        password_confirmation: "",
        organization_type: "",
        organization_name: "",
        is_organization_exist: false,
        existing_organization_code: "",
        telephone: "",
        province: {
            label: "",
            value: ""
        },
        city: {
            label: "",
            value: ""
        },
        address: ""
    })

    useEffect(() => {
        const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
        setViewportWidth(vw)

        auth
            .getProvince()
            .then(response => {
                setProvinceList([
                    ...provinceList,
                    response.data.map(
                        i => < option key = { i.id }
                        value = { i.id } > { i.name } < /option>
                    )
                ])
            })
    }, [])

    async function fetchCustomerList(keyword, isRecordNotFound = false) {
        if (isRecordNotFound) {
            return [{
                label: < div > { keyword } <
                    /div>,
                value: "",
            }]
        }

        return customer.search(keyword)
            .then((body) => {
                return body.data.data.map((customer) => ({
                    label: < div > { customer.nama_customer } < br / > { /* {customer.alamat} <br /> */ } { /* Email : {customer.email} | PIC : {customer.cp} */ } <
                        /div>,
                    value: customer.kode_customer,
                }))
            }, )
    }

    const proceedRegistration = (formData) => {
        const formDataMergeWithProvAndCityId = {
            ...formData,
            id_provinsi: parseInt(formData.id_provinsi),
            id_kabupaten: parseInt(formData.id_kabupaten)
        }

        formData = formDataMergeWithProvAndCityId

        auth
            .register(formData)
            .then(response => {
                if (response.status === 200) {
                    // save token and id_user
                    auth
                        .setUserSession(response.data.data.remember_token, response.data.data)

                    const saveTokenAndIdUser = auth.setUserSession(response.data.data.remember_token, response.data.data)
                    if (saveTokenAndIdUser.token !== '') {
                        const sourceUrl = source !== null ? "?source=" + source : ""
                        history.push('/next' + sourceUrl)
                    }
                }

                setApiCallStatus(false)
            })
    }

    const submitHandler = (e) => {
        e.preventDefault()
        setErrorMsg('')

        const formData = {
            email: formField.email,
            password: formField.password,
            perusahaan: formField.organization_name,
            is_organization_exist: formField.is_organization_exist,
            existing_organization_code: formField.existing_organization_code,
            alamat: formField.address,
            telp: formField.telephone,
            kota: formField.city.label,
            id_kabupaten: formField.city.value,
            provinsi: formField.province.label,
            id_provinsi: formField.province.value,
            bentuk_organisasi: formField.organization_type,
            nama: formField.name,
            gender: formField.gender,
            sales_offline: params.id
        }

        setApiCallStatus(true)

        auth
            .verifyEmailavailability(formData.email)
            .then(res => {

                if (res.status === 200 && res.data.availability) {
                    proceedRegistration(formData)
                } else {
                    setApiCallStatus(false)
                    setErrorMsg('Email sudah digunakan. Pernah daftar dengan email ' + formData.email + '? Apabila lupa password silahkan klik Lupa Password')
                }
            })
    }

    const addressInputField = () => {
        return <Col xs = { 24 }
        sm = { 24 }
        md = { 24 }
        lg = { 24 }
        style = {
                {...PMSAuthStyle.marginTop15px } } >
            <
            Row >
            <
            Col xs = { 24 }
        sm = { 24 }
        md = { 12 }
        lg = { 12 }
        style = {
                {...PMSAuthStyle.marginTop15px, paddingRight: '20px' } } >
            <
            label style = { PMSAuthStyle.labelStyle } >
            Provinsi { PMSAuthStyle.required } <
            /label> <
            select style = { PMSAuthStyle.inputStyle }
        onChange = {
            (e) => {
                const provinceId = e.target.value
                const optionLabel = e.target.selectedOptions[0].label

                auth
                    .getCity(provinceId)
                    .then(response => {
                        setCityList([ <
                            option key = { 0 }
                            value = { "" } > Pilih... < /option>,
                            ...response.data.map(
                                i => < option key = { i.id }
                                value = { i.id } > { i.name } < /option>
                            )
                        ])
                    })

                setFormField({
                    ...formField,
                    province: { label: optionLabel, value: provinceId },
                    city: {
                        label: "",
                        value: ""
                    }
                })
            }
        }
        value = { formField.province.value }
        required > { provinceList } <
            /select> <
            /Col> <
            Col xs = { 24 }
        sm = { 24 }
        md = { 12 }
        lg = { 12 }
        style = {
                {...PMSAuthStyle.marginTop15px } } >
            <
            label style = { PMSAuthStyle.labelStyle } >
            Kota / Kabupaten { PMSAuthStyle.required } <
            /label> <
            select style = { PMSAuthStyle.inputStyle }
        onChange = {
            (e) => {
                const cityId = e.target.value
                const optionLabel = e.target.selectedOptions[0].label

                setFormField({...formField, city: { label: optionLabel, value: cityId } })
            }
        }
        value = { formField.city.value }
        required > { cityList } <
            /select> <
            /Col> <
            /Row> <
            Row >
            <
            Col xs = { 24 }
        sm = { 24 }
        md = { 24 }
        lg = { 24 }
        style = {
                {...PMSAuthStyle.marginTop15px } } >
            <
            label style = { PMSAuthStyle.labelStyle } >
            Alamat Lengkap { PMSAuthStyle.required } <
            /label> <
            /Col> <
            Col xs = { 24 }
        sm = { 24 }
        md = { 24 }
        lg = { 24 }
        style = {
                {...PMSAuthStyle.marginTop15px } } >
            <
            input type = "text"
        style = { PMSAuthStyle.inputStyle }
        value = { formField.address }
        onChange = {
            (e) => setFormField({...formField, address: e.target.value }) }
        required / >
            <
            /Col> <
            /Row> <
            /Col>
    }

    return <PMSRegisterLayout >
        <
        Card style = {
            {
                ...PMSAuthStyle.marginTop30px,
                    borderRadius: viewportWidth < 768 ? "0px" : "20px",
                    marginBottom: viewportWidth < 768 ? "0px" : "30px",
                    textAlign: "center"
            }
        } >
        <
        Row >
        <
        Col xs = { 2 }
    sm = { 2 }
    md = { 4 }
    lg = { 3 } > < /Col> <
        Col xs = { 20 }
    sm = { 20 }
    md = { 16 }
    lg = { 18 } >
        <
        div style = { PMSAuthStyle.containerStyle } >
        <
        h1 style = {
            { fontWeight: "bold" } } > Daftar < /h1>
    Mau penawaran harga cepat & akurat ? < br / >
        Patigeni siap memberikannya

        <
        form style = {
            {...PMSAuthStyle.marginTop30px } }
    onSubmit = { submitHandler } >
        <
        Row >
        <
        Col xs = { 24 }
    sm = { 24 }
    md = { 24 }
    lg = { 24 }
    style = {
            {...PMSAuthStyle.marginTop15px } } >
        <
        label style = { PMSAuthStyle.labelStyle } >
        Nama { PMSAuthStyle.required } <
        /label> <
        /Col> <
        Col xs = { 24 }
    sm = { 24 }
    md = { 24 }
    lg = { 24 }
    style = {
            {...PMSAuthStyle.marginTop15px } } >
        <
        input type = "text"
    style = { PMSAuthStyle.inputStyle }
    value = { formField.name }
    onChange = {
        (e) => setFormField({...formField, name: e.target.value }) }
    required / >
        <
        /Col>

    <
    Col xs = { 24 }
    sm = { 24 }
    md = { 24 }
    lg = { 24 }
    style = {
            {...PMSAuthStyle.marginTop15px } } >
        <
        label style = { PMSAuthStyle.labelStyle } >
        Email { PMSAuthStyle.required } <
        /label> <
        /Col> <
        Col xs = { 24 }
    sm = { 24 }
    md = { 24 }
    lg = { 24 }
    style = {
            {...PMSAuthStyle.marginTop15px } } >
        <
        input type = "email"
    style = { PMSAuthStyle.inputStyle }
    value = { formField.email }
    onChange = {
        (e) => setFormField({...formField, email: e.target.value }) }
    required / >
        <
        div style = {
            { textAlign: "left", width: "100%", paddingTop: "5px", color: "#9D9D9D", fontSize: "10pt" } } > Harap isi data tanpa menggunakan spasi < /div> <
        /Col>

    <
    Col xs = { 24 }
    sm = { 24 }
    md = { 24 }
    lg = { 24 }
    style = {
            {...PMSAuthStyle.marginTop15px } } >
        <
        label style = { PMSAuthStyle.labelStyle } >
        Nomor Telepon { PMSAuthStyle.required } <
        /label> <
        /Col> <
        Col xs = { 24 }
    sm = { 24 }
    md = { 24 }
    lg = { 24 }
    style = {
            {...PMSAuthStyle.marginTop15px } } >
        <
        Col xs = { 20 }
    sm = { 20 }
    md = { 20 }
    lg = { 21 } >
        <
        input type = "number"
    style = { PMSAuthStyle.inputStyle }
    value = { formField.telephone }
    onChange = {
        (e) => setFormField({
            ...formField,
            telephone: e.target.value
        })
    }
    required / >
        <
        /Col> <
        /Col>

    <
    Col xs = { 24 }
    sm = { 24 }
    md = { 24 }
    lg = { 24 }
    style = {
            {...PMSAuthStyle.marginTop15px } } >
        <
        Row >
        <
        Col xs = { 24 }
    sm = { 24 }
    md = { 24 }
    lg = { 24 }
    style = {
            {...PMSAuthStyle.marginTop15px, paddingRight: '20px' } } >
        <
        label style = { PMSAuthStyle.labelStyle } >
        Password { PMSAuthStyle.required } <
        /label> <
        input type = "password"
    style = { PMSAuthStyle.inputStyle }
    value = { formField.password }
    onChange = {
        (e) => setFormField({...formField, password: e.target.value }) }
    required / >
        <
        div style = {
            { textAlign: "left", width: "100%", paddingTop: "5px", color: "#9D9D9D", fontSize: "10pt" } } >
        Gunakan minimal 8 karakter <
        /div> <
        /Col> <
        Col xs = { 24 }
    sm = { 24 }
    md = { 24 }
    lg = { 24 }
    style = {
            {...PMSAuthStyle.marginTop15px } } >
        <
        label style = { PMSAuthStyle.labelStyle } >
        Konfirmasi Password { PMSAuthStyle.required } <
        /label> <
        input type = "password"
    style = { PMSAuthStyle.inputStyle }
    value = { formField.password_confirmation }
    onChange = {
        (e) => setFormField({...formField, password_confirmation: e.target.value }) }
    required / >
        <
        div style = {
            { textAlign: "left", width: "100%", paddingTop: "5px", color: "#9D9D9D", fontSize: "10pt" } } >
        Password harus sama <
        /div>

    <
    Alert message = { "Password tidak sama" }
    type = "error"
    style = {
        {...PMSAuthStyle.marginTop15px, display: formField.password === formField.password_confirmation ? "none" : "block" } }
    /> <
    /Col> <
    /Row> <
    /Col>

    <
    Col xs = { 24 }
    sm = { 24 }
    md = { 24 }
    lg = { 24 }
    style = {
            {...PMSAuthStyle.marginTop15px } } >
        <
        Row >
        <
        Col xs = { 24 }
    sm = { 24 }
    md = { 24 }
    lg = { 24 }
    style = {
            {...PMSAuthStyle.marginTop15px } } >
        <
        label style = { PMSAuthStyle.labelStyle } >
        Jenis Kelamin { PMSAuthStyle.required } <
        /label> <
        select style = { PMSAuthStyle.inputStyle }
    onChange = {
        (e) => setFormField({...formField, gender: e.target.value }) }
    required >
        <
        option value = "" > Pilih.. < /option> <
        option value = { "L" } > Laki - laki < /option> <
        option value = { "P" } > Perempuan < /option> <
        /select> <
        Alert message = { "Jenis Kelamin harus diisi" }
    type = "error"
    style = {
        {...PMSAuthStyle.marginTop15px, display: formField.gender == "" && formField.password === formField.password_confirmation ? "block" : "none" } }
    /> <
    /Col> <
    /Row> <
    /Col>

    <
    Col xs = { 24 }
    sm = { 24 }
    md = { 24 }
    lg = { 24 }
    style = {
            {...PMSAuthStyle.marginTop15px } } >
        <
        label style = { PMSAuthStyle.labelStyle } >
        Bentuk Organisasi { PMSAuthStyle.required } <
        /label> <
        select style = { PMSAuthStyle.inputStyle }
    onChange = {
        (e) => setFormField({...formField, organization_type: e.target.value }) }
    required >
        <
        option value = "" > Pilih.. < /option> <
        option value = { "perusahaan" } > Perusahaan < /option> <
        option value = { "perseorangan" } > Perseorangan < /option> <
        /select> <
        Alert message = { "Bentuk Organisasi harus dipilih" }
    type = "error"
    style = {
        {...PMSAuthStyle.marginTop15px, display: formField.organization_type === "" && formField.gender !== "" ? "block" : "none" } }
    /> <
    /Col>

    <
    Col xs = { 24 }
    sm = { 24 }
    md = { 24 }
    lg = { 24 }
    style = {
            {...PMSAuthStyle.marginTop15px } } >
        <
        label style = { PMSAuthStyle.labelStyle } >
        Pilih Organisasi { PMSAuthStyle.required } <
        /label> <
        DebounceSelectOrganization
    onAddOrganizationClicked = {
        (val) => {
            setOptionOnNotFound([{
                label: < div > { val } <
                    /div>,
                value: val
            }])
        }
    }
    optionOnNotFound = { optionOnNotFound }
    value = { customerInfoField.company }
    fetchOptions = { fetchCustomerList }
    onChange = {
        newValue => {
            const firstFiveChar = newValue.value.substring(0, 5)
            const isOrganizationExist = firstFiveChar == "1504C"

            setFormField({
                ...formField,
                organization_name: isOrganizationExist ? "" : newValue.value,
                is_organization_exist: isOrganizationExist,
                existing_organization_code: isOrganizationExist ? newValue.value : ""
            })

            setCustomerInfoField({...customerInfoField, company: newValue })
        }
    }
    style = {
        {
            width: '100%',
            textAlign: 'left',
            border: "1px solid white",
            borderBottom: "3px solid #EBEBEB",
        }
    }
    required
        /
        >
        <
        Alert message = { "Organisasi harus diisi" }
    type = "error"
    style = {
        {...PMSAuthStyle.marginTop15px, display: formField.existing_organization_code === "" && formField.organization_type !== "" ? "block" : "none" } }
    /> <
    /Col>

    {
        // cek apakah customer memilih salah satu existing organisasi
        customerInfoField.company.value.length === 0 || (customerInfoField.company.value.length >= 5 && customerInfoField.company.value.substring(0, 5) == "1504C") ? // tidak menampilkan input provinsi, kota dan alamat lengkap ketika customer memilih existing organisasi
            <
            div > < /div> : // menampilkan input provinsi, kota dan alamat lengkap ketika customer tidak memilih existing organisasi
            addressInputField()
    } <
    /Row>

    <
    Alert message = { errorMsg }
    type = "error"
    style = {
        {...PMSAuthStyle.marginTop15px, display: errorMsg == "" ? "none" : "block" } }
    />

    <
    table style = {
            { width: "100%", marginTop: "40px" } } >
        <
        tbody >
        <
        tr >
        <
        td style = {
            { textAlign: "left" } } >
        <
        Link to = '/login'
    style = {
            { textDecoration: "none", color: "#22A99E", fontWeight: 700, paddingLeft: "5px" } } >
        Login <
        /Link> <
        /td> <
        td style = {
            { textAlign: "right" } } >
        <
        button type = "submit"
    style = {!apiCallStatus ? PMSAuthStyle.buttonStyle : PMSAuthStyle.buttonStyleDisabled }
    disabled = { apiCallStatus } > Daftar < /button> <
        /td> <
        /tr> <
        /tbody> <
        /table> <
        /form> <
        /div> <
        /Col> <
        Col xs = { 2 }
    sm = { 2 }
    md = { 4 }
    lg = { 3 } > < /Col> <
        /Row> <
        /Card> <
        /PMSRegisterLayout>
}

export default PMSRegister