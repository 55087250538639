import AuthApi from '../../../../app/utils/auth'
import { Row, Col, Alert, Card } from "antd"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import useQuery from '../../main/hooks/useQuery'
import { useHistory } from "react-router-dom"
import { PMSRegisterLayout, PMSAuthStyle } from '../../../../component/layout/PMSAuthLayout'

const PMSNewPassword = () => {
    
    const [errorMsg, setErrorMsg] = useState("")
    const [successMsg, setSuccessMsg] = useState("")
    const [apiCallStatus, setApiCallStatus] = useState(false)

    const [password, setPassword] = useState("")
    const [passwordConfirmation, setPasswordConfirmation] = useState("")

    const auth = new AuthApi()
    const { token } = useParams()
    const query = useQuery()
    const source = query.get('source')
    const history = useHistory()
    const [viewportWidth, setViewportWidth] = useState()

    useEffect(() => {
        const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
        setViewportWidth(vw)
    }, [])

    useEffect(() => {
        if (password !== passwordConfirmation) setErrorMsg("Password tidak sama")
        else setErrorMsg("")
    })

    const changePassword = (e) => {
        e.preventDefault()
        if (errorMsg !== "") return
        
        setErrorMsg("")
        setSuccessMsg("")

        setApiCallStatus(true)

        auth
            .changePassword(token, password)
            .then(response => {
                setApiCallStatus(false)
                setSuccessMsg(response.data.msg)
                setTimeout(() => {
                    setSuccessMsg("")
                }, 5000);

                const user = response.data.data
                const sourceUrl = source != null ? "?source=" + source : ""

                localStorage.setItem('token', user.remember_token)
                localStorage.setItem('user', JSON.stringify(user))
                history.push('/' + sourceUrl)
            })
            .catch((err) => {
                setApiCallStatus(false)
                setErrorMsg(err)
            })
    }

    return <PMSRegisterLayout>
        <Card style={{
            ...PMSAuthStyle.marginTop30px,
            borderRadius: viewportWidth < 768 ? "0px" : "20px",
            marginBottom: viewportWidth < 768 ? "0px" : "30px",
            textAlign: "center"
        }}>
            <div style={PMSAuthStyle.containerStyle}>
                <h1 style={{ fontWeight: "bold" }}>Ubah Password</h1>

                <span style={{ color: "#212934", fontWeight: "500", ...PMSAuthStyle.marginTop30px }}>
                    Atur kata sandi baru
                </span>

                <form style={{ ...PMSAuthStyle.marginTop30px }} onSubmit={changePassword} >
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24} style={{ ...PMSAuthStyle.marginTop15px }}>
                            <label style={PMSAuthStyle.labelStyle}>
                                Password {PMSAuthStyle.required}
                            </label>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} style={{ ...PMSAuthStyle.marginTop15px }}>
                            <input type="password" minLength="8" value={password} onChange={(e) => setPassword(e.target.value)} style={PMSAuthStyle.inputStyle} required />
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24} style={{ ...PMSAuthStyle.marginTop15px }}>
                            <label style={PMSAuthStyle.labelStyle}>
                                Konfirmasi Password {PMSAuthStyle.required}
                            </label>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} style={{ ...PMSAuthStyle.marginTop15px }}>
                            <input type="password" minLength="8" value={passwordConfirmation} onChange={(e) => setPasswordConfirmation(e.target.value)} style={PMSAuthStyle.inputStyle} required />
                        </Col>
                    </Row>

                    <Alert message={successMsg} type="success" style={{ ...PMSAuthStyle.marginTop15px, display: successMsg == "" ? "none" : "block" }} />
                    <Alert message={errorMsg} type="error" style={{ ...PMSAuthStyle.marginTop15px, display: errorMsg == "" ? "none" : "block" }} />
                    <button type="submit"
                        style={{ ...(!apiCallStatus ? PMSAuthStyle.buttonStyle : PMSAuthStyle.buttonStyleDisabled), marginBottom: "0px" }}
                        disabled={apiCallStatus}>
                        Simpan
                    </button>
                </form>
            </div>
        </Card>
    </PMSRegisterLayout>
}

export default PMSNewPassword