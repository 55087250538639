import { Link } from "react-router-dom"
import { Col, Row, Alert, Card } from "antd"
import { useState } from "react"
import { PMSAuthLayout, PMSAuthStyle } from "../../../../component/layout/PMSAuthLayout"
import { useLocation } from 'react-router-dom'
import { useHistory } from "react-router-dom"
import useQuery from '../../main/hooks/useQuery'
import AuthApi from '../../../../app/utils/auth'

const PMSLogin = () => {
    
    const viewportWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)

    const location = useLocation()
    const [formField, setFormField] = useState({ email: "", password: "" })
    const [errorMsg, setErrorMsg] = useState('')
    const [apiCallStatus, setApiCallStatus] = useState(false)
    const history = useHistory()
    const query = useQuery()
    const source = query.get('source')

    const submitHandler = (e) => {
        e.preventDefault();
        setErrorMsg("");
        setApiCallStatus(true);

        const email = formField.email;
        const password = formField.password;

        const auth = new AuthApi();
        auth
            .login(email, password)
            .then(response => {
                setApiCallStatus(false);

                const isLoginSucceed = response.status === 200;

                if (isLoginSucceed) {
                    // save token and id_user
                    const saveTokenAndIdUser = auth.setUserSession(response.data.user.remember_token, response.data.user);

                    if (saveTokenAndIdUser.token !== '') {
                        const splitCurrUrl = window.location.href.split('/');
                        let formId = '';
                        if (splitCurrUrl.length > 0) {
                            const splitQuestionMark = splitCurrUrl[splitCurrUrl.length - 1].split('?');
                            if (splitQuestionMark.length > 0) formId = splitQuestionMark[0];
                        }

                        const sourceUrl = source != null ? "?source=" + source : "";

                        const isLoginPage = location.pathname == '/login';
                        const isRegisterPage = location.pathname == '/register';
                        if (isLoginPage || isRegisterPage) {
                            history.push('/' + sourceUrl);

                            return;
                        }

                        history.push('/' + formId + sourceUrl);
                    }
                } else {
                    setErrorMsg("Login gagal, mohon periksa email / kata sandi Anda");
                }
            })
    }

    return <PMSAuthLayout>
        <Card style={{ 
            borderRadius: viewportWidth < 768 
                ? "0px" 
                : "20px",
            textAlign: "center",
            height: "500px",
            marginTop: viewportWidth < 768
                ? "0px"
                : "30px"
         }}>
            <Row>
                <Col xs={0} sm={0} md={0} lg={3}></Col>
                <Col xs={24} sm={24} md={24} lg={18}>
                    <div style={PMSAuthStyle.containerStyle}>
                        <h1 style={{ fontWeight: "bold" }}>Masuk</h1>
                        Mau penawaran harga cepat & akurat? <br />
                        Patigeni siap memberikannya
                        
                        <form style={{ marginTop: "10px" }} onSubmit={submitHandler} >
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24} style={{ ...PMSAuthStyle.marginTop15px }}>
                                    <label style={PMSAuthStyle.labelStyle}>
                                        Email {PMSAuthStyle.required}
                                    </label>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} style={{ ...PMSAuthStyle.marginTop15px }}>
                                    <input type="email" style={PMSAuthStyle.inputStyle} value={formField.email} onChange={(e) => setFormField({
                                        ...formField,
                                        email: e.target.value
                                    })} required />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} style={{ ...PMSAuthStyle.marginTop15px }}>
                                    <label style={PMSAuthStyle.labelStyle}>
                                        Password {PMSAuthStyle.required}
                                    </label>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} style={{ ...PMSAuthStyle.marginTop15px }}>
                                    <input type="password" style={PMSAuthStyle.inputStyle} value={formField.password} onChange={(e) => setFormField({ ...formField, password: e.target.value })} required />
                                </Col>
                            </Row>
                            <div style={{ textAlign: "right", ...PMSAuthStyle.marginTop30px }}>
                                <Link to='/forgot-password' style={{ textDecoration: "none", color: "#B7B8B9", fontWeight: 700 }}>
                                    Lupa Password?
                                </Link>
                            </div>

                            <Alert message={errorMsg} type="error" style={{ ...PMSAuthStyle.marginTop15px, display: errorMsg == "" ? "none" : "block" }} />
                            <button type="submit" style={{ ...(!apiCallStatus ? PMSAuthStyle.buttonStyle : PMSAuthStyle.buttonStyleDisabled), width: "100%", marginTop: "20px", marginBottom: "80px" }} disabled={apiCallStatus}>Login</button>
                        </form>
                    </div>

                    <div style={PMSAuthStyle.registerStyle}>
                        <Link to='/register' style={{ textDecoration: "none", color: "#22A99E", fontWeight: 700, paddingLeft: "5px" }}>
                            Belum punya akun?
                        </Link>
                    </div>
                </Col>
                <Col xs={0} sm={0} md={0} lg={3}></Col>
            </Row>
        </Card>
    </PMSAuthLayout>
}

export default PMSLogin