import { Alert, Col, Row, Card } from "antd"
import { Link } from "react-router-dom"
import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import useQuery from '../../main/hooks/useQuery'
import AuthApi from '../../../../app/utils/auth'
import { PMSRegisterLayout, PMSAuthStyle } from "../../../../component/layout/PMSAuthLayout"

const PMSForgotPassword = () => {

    const [viewportWidth, setViewportWidth] = useState()

    useEffect(() => {
        const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
        setViewportWidth(vw)
    }, [])

    const [formField, setFormField] = useState({ email: "" })
    const [errorMsg, setErrorMsg] = useState('')
    const [apiCallStatus, setApiCallStatus] = useState(false)
    const history = useHistory()
    const query = useQuery()
    const source = query.get('source')

    const submitHandler = (e) => {
        e.preventDefault();
        setErrorMsg("");
        setApiCallStatus(true);

        const email = formField.email;

        const auth = new AuthApi();
        auth
            .forgotPassword(email)
            .then(response => {
                setApiCallStatus(false);

                const token = response.data.data;
                const sourceUrl = source != null ? "?source=" + source : "";
                history.push('/' + token + '/confirm' + sourceUrl);
            })
    }

    return <PMSRegisterLayout>
        <Card style={{
            ...PMSAuthStyle.marginTop30px,
            borderRadius: viewportWidth < 768 ? "0px" : "20px",
            marginBottom: viewportWidth < 768 ? "0px" : "30px",
            textAlign: "center"
        }}>
            <Row>
                <Col xs={2} sm={2} md={4} lg={3}></Col>
                <Col xs={20} sm={20} md={16} lg={18}>
                    <div style={PMSAuthStyle.containerStyle}>
                        <h1 style={{ fontWeight: "bold" }}>Lupa Password</h1>

                        <span style={{ color: "#212934", fontWeight: "500", ...PMSAuthStyle.marginTop30px }}>
                            Memulihkan password Anda dimulai dengan <br />
                            email yang ditautkan ke akun Anda.
                        </span>

                        <form style={{ ...PMSAuthStyle.marginTop30px }} onSubmit={submitHandler}>
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24} style={{ ...PMSAuthStyle.marginTop15px }}>
                                    <label style={PMSAuthStyle.labelStyle}>
                                        Email {PMSAuthStyle.required}
                                    </label>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} style={{ ...PMSAuthStyle.marginTop15px }}>
                                    <input type="email" style={PMSAuthStyle.inputStyle} value={formField.email} onChange={(e) => setFormField({ ...formField, email: e.target.value })} required />
                                </Col>
                            </Row>

                            <Alert message={errorMsg} type="error" style={{ ...PMSAuthStyle.marginTop15px, display: errorMsg == "" ? "none" : "block" }} />
                            <button type="submit" style={{ ...(!apiCallStatus ? PMSAuthStyle.buttonStyle : PMSAuthStyle.buttonStyleDisabled), marginTop: '20px', width: '100%' }} disabled={apiCallStatus}>Reset Password</button>
                            <div style={{
                                marginTop: '10px',
                                width: "100%",
                                opacity: ".5",
                                textDecoration: "none",
                                color: "#212934",
                                fontWeight: 700,
                                padding: "10px",
                                border: "2px solid #808080",
                                borderRadius: "5px"
                            }}>
                                <Link style={{ textDecoration: 'none', color: "#808080" }} to='/login'>
                                    Kembali ke Masuk
                                </Link>
                            </div>
                        </form>
                    </div>
                </Col>
                <Col xs={2} sm={2} md={4} lg={3}></Col>
            </Row>
        </Card>
    </PMSRegisterLayout>
}

export default PMSForgotPassword